import {Component, OnInit} from '@angular/core';
import {DataService} from './services/data.service';
import {Storage} from '@ionic/storage';
// @ts-ignore
import Parse from 'parse';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    {title: 'Accueil', url: '/home', icon: 'home'},
    {title: 'Patrimoine', url: '/patrimoine', icon: 'map'},
    {title: 'Calendrier', url: '/rgpd', icon: 'calendar'},
    {title: 'Messagerie', url: '/rgpd', icon: 'chatbubbles'},
    //{title: 'RGPD', url: '/rgpd', icon: 'document-text'}
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];



  constructor(private storage: Storage,
              private platform: Platform,
              private data: DataService) {
    this.init();
  }

  async init() {

  }

  async ngOnInit() {

  }
}
