import {Injectable, OnInit} from '@angular/core';
import {StorageService} from './storage.service';
// @ts-ignore
import Parse from 'parse';
import {Platform} from '@ionic/angular';
import {Rite} from '../model/rite';
import {RitesService} from './rites.service';
import {LayerGroup} from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  user: User;

  data = {
    state: {
      installation: undefined
    },
    poiList: null,
    riteList: null
  };

  map: any;
  layer: LayerGroup;

  constructor(private storage: StorageService,
              private platform: Platform,
              private rites: RitesService) {

    this.storage.initStorage().then(async r => {
      console.log('--- Initialising data', this.data);
      await this.initData();
      console.log('--- Initialising remote link', this.data);
      await this.initRemoteLink();
      console.log('--- Saving data', this.data);
      await this.save();
    });

  }

  async initData() {
    const temp = await this.storage.get('data');
    if (temp) {
      this.data = temp;
    }
    console.log('Loaded data : ', this.data);

  }

  async initRemoteLink(force = false) {
    if (this.data.state.installation === undefined || force) {
      console.log('no install, initiating');
      const install = new Parse.Installation();
      install.set('deviceType', this.platform.platforms().toString());
      this.data.state.installation = await install.save();

      console.log('result : ', this.data.state.installation);
    } else {
      console.log('Install ID found', this.data.state.installation.id);
    }
  }

  async save() {
    console.log('saving', this.data);
    await this.set('data', this.data);
  }

  private set(key: string, value: any) {
    return this.storage.set(key, value);
  }

  async getRiteList(noCache: boolean = false): Promise<Rite[]> {
    this.riteList = await this.rites.getListeRites(this.data.riteList, noCache);
    console.log('getinng rites list : this.riteList');
    return this.data.riteList;
  }

  set riteList(value: Rite[]) {
    console.log('setting rites list : ', this.data.riteList);
    this.data.riteList = value;
    this.save();
  }
}

export interface User {
  nom: string;
  prenom: string;
  avatar: string;
  email: string;
}

export interface Installation {
  id;
}

export interface QueryResult {
  attributes: any;
  id: string;
}
