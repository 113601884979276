import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import * as Parse from 'parse';
import { IonicStorageModule } from '@ionic/storage-angular';
import {DataService} from './services/data.service';
import {KeyService} from './services/key.service';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import {LeafletDirective, LeafletModule} from '@asymmetrik/ngx-leaflet';
registerLocaleData(localeFr);
// import Parse from 'parse';
// @ts-ignore
Parse.serverURL = 'https://philorientapp.b4a.io';
Parse.initialize('39qPJWOdPrpETwYldAMpSlquGZUPOzV063XcdEAU', '8UQd5hnWhtJIsh15o05vbaaywJ4T1prwyqsR5zIT');


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, LeafletModule,
    IonicStorageModule.forRoot()],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    DataService, KeyService, LeafletDirective],
  bootstrap: [AppComponent],
})
export class AppModule {}
