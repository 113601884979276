import {Injectable} from '@angular/core';
import Parse from 'parse';
import {Rite} from '../model/rite';

@Injectable({
  providedIn: 'root'
})
export class RitesService {
  lock = false;

  constructor() {
  }

  async getListeRites(riteList, noCache: boolean = false): Promise<Rite[]> {
    if (!this.lock) {
      // Verrouillage pour éviter le spam de requete dû au ngfor du template
      this.lock = true;
      if (noCache || riteList === undefined || riteList === []) {
        const rites = Parse.Object.extend('rites');
        const query = new Parse.Query(rites);

        riteList = this.parseData(await query.find());
        this.lock = false;
        return riteList;
      } else {
        this.lock = false;
        return riteList;
      }
    } else {
       return riteList;
    }

  }

  parseData(results) {
    let tempList = [];
    results.forEach(result => {
      let tempObject = new Object();
      tempObject['objectId'] = result._getId();
      tempObject['nom'] = result.get('nom');
      // @ts-ignore
      tempList.push(tempObject);
    });
    return tempList;
  }
}
