import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;
  ready = false;

  constructor(private storage: Storage) {
    this.initStorage();
  }

  async initStorage() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
    this.ready = true;
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    /*    return new Promise(async (resolve, reject) => {
          await this._storage?.set(key, value);
        });*/

    await this._storage.set(key, value);

  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async get(key: string) {
    return await this._storage.get(key);
  }
}
